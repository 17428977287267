import {graphql} from 'gatsby';
import React from 'react';
import {useTranslation} from 'react-i18next';
import tw, {styled} from 'twin.macro';

import {Container} from '../components/Grid';
import Layout from '../components/Layout';
import SEO from '../components/Seo';

const StyledContainer = styled(Container)`
  max-width: 1180px;
  margin-top: ${props => props.theme.mobileHeaderHeight};
  padding-top: 60px;

  @media ${props => props.theme.screens.md} {
    margin-top: ${props => props.theme.headerHeight};
  }

  h1 {
    ${tw`text-primary mb-8 text-center`}
    font-size: 32px;
  }

  h3 {
    ${tw`text-primary mb-4`}
    font-size: 32px;
  }

  p {
    ${tw`font-medium mb-4`}
  }

  ul {
    ${tw`mb-4`}
    list-style: disc;
    padding-left: 50px;
  }

  li {
    ${tw`font-medium`}
  }
`;

const gdpr = () => {
  const {t} = useTranslation();
  return (
    <Layout>
      <SEO title={t('seo_title')} description={'seo_description'} />
      <StyledContainer>
        <h1>{t('Informace o zpracování osobních údajů')}</h1>
        <p>
          <strong>
            {t(
              'Zpracování osobních údajů dle nařízení Evropského parlamentu a Rady (EU) 2016/679 o ochraně fyzických osob v souvislosti se zpracováním osobních údajů a o volném pohybu těchto údajů (General Data Protection Regulation) (dále jen: „GDPR”)',
            )}
          </strong>
        </p>
        <p>
          <strong>{t('I. Základní ustanovení')}</strong>
          <br />
          {t(
            '1. Tyto informace jsou poskytované na základě povinnosti dle čl. 13 GDPR',
          )}
          <br />
          {t(
            '2. Správcem osobních údajů dle čl. 4 bod 7 GDPR je KUBRAK GROUP s.r.o. IČ: 03768899 (dále jen: „správce“).',
          )}
          <br />
          {t('3. Kontaktními údaji správce jsou:')}
        </p>
        <ul>
          <li>{t('adresa: Dražického náměstí 65/10, Praha 1 11800')}</li>
          <li>{t('e-mailová adresa: info@magnolia-uklid.cz')}</li>
          <li>{t('telefonní číslo: +420777622568')}</li>
        </ul>
        <p>
          {t('4. Správce nejmenoval pověřence pro ochranu osobních údajů.')}
        </p>
        <p>
          <strong>
            {t('II. Definice')}
            <br />
            {t('Pro účely GDPR se rozumí:')}
          </strong>
        </p>
        <p>
          {t(`
          1. „Správcem“ fyzická nebo právnická osoba, orgán veřejné moci, agentura nebo jiný subjekt, který sám nebo společně s jinými určuje účely a prostředky zpracování osobních údajů; jsou-li účely a prostředky tohoto zpracování určeny právem Unie či členského státu, může toto právo určit dotčeného správce nebo zvláštní kritéria pro jeho určení.
        `)}
        </p>
        <p>
          {t(`
          2. „Osobními údaji“ veškeré informace o identifikované nebo identifikovatelné fyzické osobě; identifikovatelnou fyzickou osobou je fyzická osoba, kterou lze přímo či nepřímo identifikovat, zejména odkazem na určitý identifikátor, například jméno, identifikační číslo, lokační údaje, síťový identifikátor nebo na jeden či více zvláštních prvků fyzické, fyziologické, genetické, psychické, ekonomické, kulturní nebo společenské identity této fyzické osoby.
          `)}
        </p>
        <p>
          {t(`
            3. „Zpracováním“ jakákoliv operace nebo soubor operací s osobními údaji nebo soubory osobních údajů, který je prováděn pomocí či bez pomoci automatizovaných postupů, jako je shromáždění, zaznamenání, uspořádání, strukturování, uložení, přizpůsobení nebo pozměnění, vyhledání, nahlédnutí, použití, zpřístupnění přenosem, šíření nebo jakékoliv jiné zpřístupnění, seřazení či zkombinování, omezení, výmaz nebo zničení.
          `)}
        </p>
        <p>
          {t(`
            5. „Souhlasem“ subjektu údajů jakýkoli svobodný, konkrétní, informovaný a jednoznačný projev vůle, kterým subjekt údajů dává prohlášením či jiným zjevným potvrzením své svolení ke zpracování svých osobních údajů;
          `)}
        </p>
        <p>
          <strong>{t('III. Zpracovávané osobní údaje')}</strong>
        </p>
        <p>
          {t(`
         1. Správce zpracovává nezbytné osobní údaje subjektu údajů z důvodu plnění smluvní povinnosti (např. vyřízení objednávky či rezervace), z důvodu oprávněného zájmu správce a na základě svobodného souhlasu subjektu údajů pro konkrétní účel.
        `)}
        </p>
        <p>
          {t(`
         2. Těmito údaji jsou především:
        `)}
        </p>
        <ul>
          <li>
            {t(
              'identifikační a adresní údaje: oslovení, akademický titul, jméno, příjmení, datum narození, fakturační adresa, doručovací nebo jiná kontaktní adresa, IČ, DIČ',
            )}
          </li>
          <li>
            {t(
              'elektronické kontaktní údaje: telefon, mobilní telefon, fax, e-mailová adresa',
            )}
          </li>
          <li>
            {t(
              'jiné elektronické údaje: IP adresa, cookies, autentizační certifikáty, identifikátory v sociálních sítích a komunikačních platformách /např. Skype/, lokační údaje zařízení užívaného objednatelem, atd.',
            )}
          </li>
          <li>
            {t(
              'další osobní údaje spojené se smluvním vztahem: jako např. číslo bankovního účtu.',
            )}
          </li>
          <li>
            {t(
              'osobní údaje: které vznikají jako výsledek adresných analýz spotřebitelského chování objednatele, záznamy o historii a plnění platebních povinností',
            )}
          </li>
        </ul>
        <p>
          {t(`
         3. Správce neprovádí automatizované individuální rozhodování, včetně profilování dle čl. 22 GDPR nemá-li k tomuto výslovný souhlas subjektu údajů dle čl. 22 odst. 2. písm. c) GDPR
        `)}
        </p>

        <p>
          <strong>
            {t('IV. Zákonnost zpracování a účel zpracování osobních údajů')}
          </strong>
        </p>
        <p>
          {t(`
            1. Zákonný důvod zpracování osobních údajů:
        `)}
        </p>
        <ul>
          <li>
            {t(
              'zpracování je nezbytné pro plnění smlouvy mezi subjektem údajů a správcem dle čl. 6 odst. 1 písm. b) GDPR',
            )}
          </li>
          <li>
            {t(
              'zpracování je nezbytné pro účely oprávněného zájmu správce (např. poskytování přímého marketingu) dle čl. 6 odst. 1 písm. f) GDPR,',
            )}
          </li>
          <li>
            {t(
              'zpracování je nezbytné pro daný konkrétní účel např. poskytování přímého marketingu dle čl. 6 odst. 1 písm. a) GDPR ve spojení s § 7 odst. 2 zákona č. 480/2004 Sb., o některých službách informační společnosti v případě, že nedošlo k objednávce zboží nebo služby.',
            )}
          </li>
          <li>{t('takovém případě')}</li>
          <li>
            {t(
              'je správce schopen doložit, že subjekt údajů udělil souhlas se zpracováním svých osobních údajů.',
            )}
          </li>
          <li>{t('subjekt údajů má právo svůj souhlas kdykoli odvolat.')}</li>
        </ul>
        <p>
          {t(`
            2. Účel zpracování osobních údajů:
        `)}
        </p>
        <ul>
          <li>
            {t(
              'vyřízení objednávky, rezervace či jiné poskytované služby vyplývající ze smluvního vztahu mezi subjektem údajů a správcem; pro úspěšné plnění této smluvní povinnosti jsou vyžadovány osobní údaje jako je jméno, adresa, telefonní číslo, e-mail či jiné kontaktní údaje, bez poskytnutí těchto osobních údajů není možné uzavření a plnění smlouvy ze strany správce.',
            )}
          </li>
          <li>
            {t(
              'občasné zasílání obchodních sdělení, newsletterů a další marketingové aktivity.',
            )}
          </li>
        </ul>
        <p>
          <strong>{t('V. Uchovávání osobních údajů')}</strong>
        </p>
        <p>
          {t(`
            1. Správce uchovává osobní údaje
        `)}
        </p>
        <ul>
          <li>
            {t(
              'po nezbytnou dobu k výkonu práv a povinností vyplývajících ze smluvního vztahu mezi subjektem údajů a správcem, pro účely plnění zákonných povinností a uplatnění nároků z těchto smluvních vztahů',
            )}
          </li>
          <li>
            {t(
              'po dobu, než je odvolán souhlas se zpracováním osobních údajů pro konkrétní účel jsou-li osobní údaje zpracovávány na základě souhlasu subjektu údajů',
            )}
          </li>
        </ul>
        <p>
          {t(`
           2. Po uplynutí doby uchovávání osobních údajů dochází k výmazu osobních údajů
        `)}
        </p>
        <p>
          {t(`
            3. K výmazu osobních údajů dochází také na základě požadavku subjektu údajů dle čl. 17 GDPR Právo na výmaz („právo být zapomenut“), nejsou-li již nadále potřebné k plnění práv a povinností vyplývajících ze smluvního vztahu mezi subjektem údajů a správcem
        `)}
        </p>
        <p>
          <strong>
            {t(
              'VI. Příjemci osobních údajů (zpracovatelé, subdodavatelé a jiné dotčené osoby)',
            )}
          </strong>
        </p>
        <p>
          {t(`
          1. Příjemci osobních údajů dle čl. 4 GDPR jsou osoby
        `)}
        </p>
        <ul>
          <li>
            {t(
              'podílející se na dodání zboží či služeb na základě smluvního vztahu mezi subjektem údajů a správcem',
            )}
          </li>
          <li>
            {t(
              'poskytovatelé a administrátoři webových stránek a ostatních služeb v souvislosti s provozováním e-shopu či rezervačního systému',
            )}
          </li>
          <li>
            {t(
              'poskytovatelé zajišťující marketingové, statistické či jiné služby související s provozováním e-shopu či rezervačního systému',
            )}
          </li>
        </ul>
        <p>
          <strong>{t('VII. Práva subjektu údajů')}</strong>
        </p>
        <p>{t('Subjekt údajů má dle GDPR tato práva')}</p>
        <ul>
          <li>
            {t(
              'právo na transparentní informace, sdělení a postupy pro výkon práv subjektu údajů dle čl. 12, 13 a 14 GDPR',
            )}
          </li>
          <li>
            {t(
              'právo subjektu údajů na přístup k osobním údajům dle čl. 15 GDPR',
            )}
          </li>
          <li>{t('právo na opravu osobních údajů dle čl. 16 GDPR')}</li>
          <li>
            {t(
              'právo na výmaz osobních údajů dle čl. 17 GDPR („právo být zapomenut“)',
            )}
          </li>
          <li>{t('právo na omezení zpracování dle čl. 18 GDPR')}</li>
          <li>
            {t(
              'právo na oznámení ohledně opravy, výmazu nebo omezení zpracování dle čl. 19 GDPR',
            )}
          </li>
          <li>{t('právo na přenositelnost údajů dle čl. 20 GDPR.')}</li>
          <li>{t('právo vznést námitku dle čl. 21 GDPR')}</li>
          <li>
            {t(
              'právo odvolat dříve udělený souhlas se zpracováním osobních údajů dle čl. 7 odst. 3.',
            )}
          </li>
          <li>
            {t(
              'právo podat stížnost u Úřadu pro ochranu osobních údajů v případě, že se domnívá, že bylo porušeno právo na ochranu osobních údajů',
            )}
          </li>
        </ul>
        <p>
          <strong>{t('VIII. Závěrečná ustanovení')}</strong>
        </p>
        <p>
          {t(`
          1. Subjekt údajů má právo seznámit se s těmito podmínkami ochrany osobních údajů na základě GDPR vždy před potvrzením služby, u které je k plnění smluvní povinnosti nezbytné zpracování osobních údajů.
        `)}
        </p>
        <p>
          {t(`
         2. Je-li sběr osobních údajů realizován za jiným učelem než k plnění práv a povinností vyplývajících ze smluvního vztahu mezi správcem a subjektem údajů nebo nevede-li k tomuto sběru oprávněný zájem správce nebo plnění zákonné povinnosti, zavazuje se správce získat konkrétní a svobodný souhlas subjektu údajů pro jeden nebo více stanovených účelů.
        `)}
        </p>
      </StyledContainer>
    </Layout>
  );
};

export default gdpr;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        language: {eq: $language}
        ns: {in: ["common", "seo_information_processing"]}
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
